import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useLecturesList() {
  // Use toast
  const toast = useToast()

  const refLectureListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', sortable: true },
    { key: 'video_thumb', sortable: false },
    { key: 'title_en', sortable: true },
    { key: 'course', sortable: false },
    { key: 'category', sortable: false },
    { key: 'sub_category', sortable: false },
    { key: 'created_by', sortable: false },
    { key: 'created_at', sortable: false },
    { key: 'is_active', sortable: false },
    { key: 'is_free_content', sortable: false },
    { key: 'title_ar', sortable: false },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalLectures = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const courseFilter = ref(null)
  const categoryFilter = ref(null)
  const subCategoryFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refLectureListTable.value ? refLectureListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalLectures.value,
    }
  })

  const refetchData = () => {
    refLectureListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, courseFilter, categoryFilter,subCategoryFilter], () => {
    refetchData()
  })

  const fetchLectures = (ctx, callback) => {
    store
      .dispatch('lecture/fetchLectures', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
        plan: planFilter.value,
        course: courseFilter.value,
        category: categoryFilter.value,
        sub_category: subCategoryFilter.value,
      })
      .then(response => {
        const { lectures, total } = response.data

        callback(lectures)
        totalLectures.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchCourseLectures = (ctx, callback) => {
    store
        .dispatch('lecture/fetchCourseLectures', {
          q: searchQuery.value,
          perPage: perPage.value,
          page: currentPage.value,
          sortBy: sortBy.value,
          sortDesc: isSortDirDesc.value,
          role: roleFilter.value,
          plan: planFilter.value,
          course: courseFilter.value,
          category: categoryFilter.value,
          sub_category: subCategoryFilter.value,
        })
        .then(response => {
          const { lectures, total } = response.data

          callback(lectures)
          totalLectures.value = total
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching users list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchLectures,
    fetchCourseLectures,
    tableColumns,
    perPage,
    currentPage,
    totalLectures,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLectureListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    planFilter,
    courseFilter,
    categoryFilter,
    subCategoryFilter,
  }
}
